<script>
import api from '@/command/api'
import DetailFormGroup from '../../../components/DetailFormGroup'
// 添加租户
export default {
  name: 'tenantDetail',
  data() {
    return {
      ...api.command.getState(),
      detail: {
        tenantName: '',
        tenantId: '',
        payServiceCode: '',
        subMerchantCode: '',
        shopPayUrl: '',
        areaLevel: null,
        areaCode: [],
        shopPayBackGround: ''
      },
      cascaderList: []
    }
  },
  watch: {
    'detail.areaLevel'(a) {
      if (a) {
        this.onSelectChange(a)
      }
    }
  },
  mounted() {
    console.log('----租户---测试--')
    const { id } = this.$route.query
    id &&
      api.command.getDetail
        .call(this, {
          url: '/system/farmTenant/detail/' + id,
          host: '/bpi'
        })
        .then(result => {
          console.log(result)
          this.detail.areaCode = result.areaCode ? result.areaCode.split(',') : []
          this.$forceUpdate()
        })
  },
  methods: {
    onSelectChange(value) {
      api.command.getRegionType.call(this, {
        url: `/currency/region/freeTree?level=${value}`,
        key: 'cascaderList',
        host: '/bpi'
      })
    },
    getForm1() {
      return {
        title: '基本信息',
        type: 'cardForm',
        data: [
          {
            name: '名称',
            type: 'input',
            key: 'tenantName',
            cols: 12,
            rules: [
              {
                required: true,
                type: 'string'
              }
            ]
          },
          {
            name: '租户标识',
            type: 'input',
            cols: 12,
            key: 'tenantId',
            rules: [
              {
                required: true,
                type: 'string'
              }
            ]
          },
          {
            name: '支付服务标识',
            type: 'input',
            key: 'payServiceCode',
            cols: 12,
            rules: [
              {
                required: true
              }
            ]
          },
          {
            name: 'H5地址',
            type: 'input',
            key: 'shopPayUrl',
            cols: 12,
            rules: [
              {
                required: true,
                type: 'string'
              }
            ]
          },
          {
            name: '二级商户开通标识',
            type: 'input',
            key: 'subMerchantCode',
            cols: 12,
            rules: [
              {
                required: true,
                type: 'string'
              }
            ]
          },
          {
            name: '店铺设置',
            type: 'radioButton',
            key: 'openShopFlag',
            cols: 12,
            typeData: [
              {
                name: '不强制开店，其他租户有店都可查看',
                value: '0'
              },
              {
                name: '强制开店，当前租户必须有店铺',
                value: '1'
              }
            ]
          },
           {
            name: '手动结算',
            type: 'radioButton',
            key: 'handJsState',
            defaultValue: '1',
            cols: 12,
             rules: [
              {
                required: true,
                type: 'string'
              }
            ],
            typeData: [
              {
                name: '开启',
                value: '1'
              },
              {
                name: '关闭',
                value: '0'
              }
            ]
          }
        ]
      }
    },

    getForm2() {
      return {
        title: '行政级别',
        type: 'cardForm',
        data: [
          {
            name: '子系统级别',
            type: 'select',
            key: 'areaLevel',
            cols: 12,
            typeData: [
              {
                name: '省',
                value: '1'
              },
              {
                name: '市',
                value: '2'
              },
              {
                name: '区',
                value: '3'
              },
              {
                name: '镇',
                value: '4'
              },
              {
                name: '村',
                value: '5'
              }
            ]
          },
          {
            name: '选择具体级别',
            type: 'cascader',
            key: 'areaCode',
            cols: 12,
            typeData: this.cascaderList,
            rules: [
              {
                required: true,
                type: 'array'
              }
            ]
          }
        ]
      }
    },
    getForm3() {
      return {
        title: '付款码牌背景（1张）',
        type: 'cardForm',
        data: [
          {
            label: '图片上传',
            type: 'iconUpload',
            key: 'a1',
            typeData: [
              {
                maxLength: 1,
                key: 'shopPayBackGround',
                desc: '上传尺寸750*42px'
              }
            ]
          }
        ]
      }
    },
     getForm4() {
      return {
        title: '租户系统图标（1张）',
        type: 'cardForm',
        data: [
          {
            label: '图片上传',
            type: 'iconUpload',
            key: 'a2',
            typeData: [
              {
                maxLength: 1,
                key: 'sysIcon',
                // desc: '上传尺寸750*42px'
              }
            ]
          }
        ]
      }
    },
    getFoot() {
      const left = [
        {
          name: '提交',
          type: 'primary',
          loading: this.loading,
          sumbit: true,
          onClick: data => {
            let ym = /(?!^\d+$)(?!^[a-zA-Z]+$)[0-9a-zA-Z]{2,50}/
            if (!ym.test(data.tenantId)) {
              this.$message.warning('请输入正确的二级域名！')
              return
            }
            api.command.createDetail.call(this, {
              url: `/system/farmTenant/${data.id ? 'update' : 'add'}`,
              params: {
                ...data,
                areaCode: data.areaCode.toString()
              },
              host: '/bpi'
            })
          }
        },
        {
          name: '取消',
          type: 'default',
          onClick: () => history.back()
        }
      ]
      return {
        left
      }
    }
  },
  render() {
    return (
      <DetailFormGroup
        form={this.detail}
        foot={this.getFoot()}
        data={[this.getForm1(), this.getForm3(),this.getForm4(), this.getForm2()]}
      />
    )
  }
}
</script>

<style lang="less" scoped>
.entityInformation-detail {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
}
</style>
